import { replaceClass } from '~/javascript/utilities/dom_utilities';

class PracticeLink {
  constructor(link) {
    this.link = link;
  }

  get collapsed() {
    return this.link.data('collapse') == true;
  }

  set collapsed(val) {
    this.link.data('collapse', val);
  }

  get collapseIcon() {
    return this.link.find('i.collapse-icon');
  }

  get locationLinks() {
    return this.link.parent().find('.practice-location-items');
  }

  toggle() {
    this.collapsed = !this.collapsed;
    this.toggleIcon();
    this.toggleLocationLinks();
  }

  toggleIcon() {
    if(this.collapsed) {
      replaceClass('fa-caret-down', 'fa-caret-right', this.collapseIcon);
    } else {
      replaceClass('fa-caret-right', 'fa-caret-down', this.collapseIcon);
    }
  }

  toggleLocationLinks() {
    this.locationLinks.toggleClass('hidden', this.collapsed);
  }
}

export const render = () => {
  $j('#locations-selection-dropdown .practice-item-link').on('click', e => {
    const practiceLink = new PracticeLink($j(e.currentTarget));
    practiceLink.toggle();
  });
};
